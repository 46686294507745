let baseUrl;
if (process.env.NODE_ENV == "development") {
  baseUrl = "http://127.0.0.1:8080/";
} else {
  baseUrl = "/api/";
}
export const tokenFlag = "flow_chart_token";

// 获取url参数
export function urlParams(paramName) {
  const reg = new RegExp("[?&]" + paramName + "=([^&]*)[&]?", "i");
  const paramVal = window.location.search.match(reg);
  return paramVal == null ? "" : decodeURIComponent(paramVal[1]);
}

// 生成UUID
export function getUUID() {
  const s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";

  const uuid = s.join("");
  return uuid;
}

export async function getStudentStudy(studentStudyId) {
  const response = await fetch(
    `${baseUrl}student/student-study/getStudentStudyById/` + studentStudyId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': localStorage.getItem(tokenFlag),
        'source': 'study-platform'
      },
    }
  );
  if (response.ok) {
    const result = await response.json();
    if (result.code === 200) {
      return result.data;
    }
  }
  return null;
}

export async function saveFlow(flowChatObj) {
  const response = await fetch(`${baseUrl}student/student-study/saveFlow`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem(tokenFlag),
      'source': 'study-platform'
    },
    body: JSON.stringify(flowChatObj),
  });
  if (response.ok) {
    const result = await response.json();
    if (result.code === 200) {
      return result.data;
    }
  }
  return null;
}

// 获取七牛云上传token
export async function getTokenByKey(key) {
  const form = `key=${key}`;
  const response = await fetch(`${baseUrl}external/file/getToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: localStorage.getItem(tokenFlag),
      'source': 'study-platform'
    },
    body: form,
  });
  if (response.ok) {
    const result = await response.json();
    if (result.code === 200) {
      return result.data;
    }
  }
  return null;
}

// 获取七牛云上的流程图
export async function getCodeByUrl(url) {
  const tokenResponse = await fetch(`${url}?random=${Math.random()}`);
  if (tokenResponse.ok) {
    return tokenResponse.text();
  }
  return null;
}
