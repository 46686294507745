<template>
  <div class="containers">
    <div class="btnGroup">
      <el-button-group style="margin-right: 30px;">
        <el-button type="primary" @click="bpmnModeler.get('commandStack').undo()" size="small"
          icon="el-icon-back"></el-button>
        <el-button type="primary" @click="
          scale += 0.1;
        bpmnModeler.get('canvas').zoom(scale);
        " size="small" icon="el-icon-zoom-in"></el-button>
        <el-button type="primary" @click="center" size="small" icon="el-icon-c-scale-to-original"></el-button>
        <el-button type="primary" @click="
          scale -= 0.1;
        bpmnModeler.get('canvas').zoom(scale);
        " size="small" icon="el-icon-zoom-out"></el-button>
        <el-button type="primary" @click="bpmnModeler.get('commandStack').redo()" size="small"
          icon="el-icon-right"></el-button>
      </el-button-group>
      <el-button-group>
        <el-button type="primary" @click="getXmlstr" icon="el-icon-download" size="small">导出BPMN</el-button>
        <el-button type="primary" @click="exportSvg" icon="el-icon-download" size="small">导出SVG</el-button>
        <el-button type="primary" @click="getSvgstr" icon="el-icon-download" size="small">导出流程图</el-button>
        <el-button type="primary" @click="saveFile" icon="el-icon-tickets" size="small">保 存</el-button>
      </el-button-group>
    </div>
    <div class="canvas" ref="canvas"></div>
    <div id="js-properties-panel" class="panel"></div>
    <div class="svg" ref="svg"></div>
    <!-- 导出xml存贮为BPMN -->
    <el-dialog title="" :visible.sync="xmlFlag" width="400px">
      <el-form ref="form" size="mini" label-width="80px">
        <el-form-item label="文件名称: ">
          <el-input v-model="xmlFlieName" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xmlFlag = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="xmlDownload" size="mini">下 载</el-button>
      </span>
    </el-dialog>
    <!-- 导出svg存贮为SVG -->
    <el-dialog title="" :visible.sync="svgFlag" width="400px">
      <el-form ref="form" size="mini" label-width="80px">
        <el-form-item label="文件名称: ">
          <el-input v-model="svgFlieName" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="svgFlag = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="svgDownload" size="mini">下 载</el-button>
      </span>
    </el-dialog>
    <!-- 导出流程图片 -->
    <el-dialog title="" :visible.sync="imgFlag" width="400px">
      <el-form ref="form" size="mini" label-width="80px">
        <el-form-item label="导出类型: ">
          <el-select v-model="type" placeholder="请选择">
            <el-option label="jpg" value="jpg"></el-option>
            <el-option label="png" value="png"></el-option>
            <el-option label="bmp" value="bmp"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片名称: ">
          <el-input v-model="input" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgFlag = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="download" size="mini">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script scoped>
// bpmn-js
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
// 右边工具栏样式
import "bpmn-js-properties-panel-keeley/dist/assets/bpmn-js-properties-panel.css";
import BpmnModeler from "bpmn-js/lib/Modeler";
import propertiesPanelModule from "../../node_modules/bpmn-js-properties-panel/index";
import propertiesProviderModule from "../../node_modules/bpmn-js-properties-panel-keeley/lib/provider/activiti/index";
import activitiModdleDescriptor from "../../node_modules/activiti-bpmn-moddle/resources/activiti.json";
import xml from "../utils/xml/xml";
import customTranslate from "../utils/translate/index";
import {
  getCodeByUrl,
  getTokenByKey,
  getUUID,
  getStudentStudy,
  saveFlow,
  tokenFlag,
} from "../utils/common/commonUtils";
import * as qiniu from "qiniu-js";
// 自定义汉化模块
var customTranslateModule = {
  translate: ["value", customTranslate],
};
import svgChange from "../utils/svg/index";
import { Notification } from "element-ui";
import { Message } from "element-ui";
const flowChatObj = {
  studentStudyId: null, // 学习表id
  flowChatUrl: null, // 流程图地址(xml数据)
  flowChatPreview: null, // 流程图预览图地址(svg格式)
  uploadXML: false,
  uploadSvg: false,
};
export default {
  components: {},
  data() {
    return {
      flag: false, // 是否存在流程
      scale: 1, // 放大缩小
      // bpmn建模器
      bpmnModeler: null, // 存贮bpmnModeler实例化对象
      canvas: null, // 存节点
      option: {
        // 存贮创建名称
        id: `Process_${new Date().getTime()}`,
        name: `Flow_${new Date().getTime()}`,
      },
      imgFlag: false, // 打开流程图下载弹框
      type: "jpg", // 下载流程图的类型
      input: "", // 下载流程图的名称
      flowPath: false, // 打开新建流程图的弹窗

      xmlFlag: false, // 打开导出BPMN的弹窗
      xml: "", // xml内容
      xmlFlieName: new Date().getTime(), // BPMN文件名

      svgFlag: false, // 打开导出SVG的弹窗
      svg: "", // SVG内容
      svgFlieName: new Date().getTime(), // SVG文件名
    };
  },
  created() {
    const param = JSON.parse(window.name);
    const token = param.token;
    flowChatObj.studentStudyId = param.studentStudyId;
    if (token) {
      localStorage.setItem(tokenFlag, token);
    } else {
      localStorage.getItem(tokenFlag);
    }
  },

  methods: {
    loadFlowChat() {
      getCodeByUrl(flowChatObj.flowChatUrl).then((res) => {
        this.bpmnModeler.importXML(res, (err) => {
          if (err) {
            Notification({
              title: "提示",
              message: "加载失败",
              type: "error",
              duration: 2000,
            });
          } else {
            Notification({
              title: "提示",
              message: "加载成功",
              type: "success",
              duration: 2000,
            });
            this.flag = true;
          }
        });
      });
    },
    // 创建流程图
    createFlowChart() {
      const canvas = this.$refs.canvas;
      this.bpmnModeler = new BpmnModeler({
        container: canvas,
        keyboard: {
          bindTo: window,
        },
        propertiesPanel: {
          parent: "#js-properties-panel",
        },
        additionalModules: [
          // 左边工具栏以及节点
          propertiesProviderModule,
          // 右边的工具栏
          propertiesPanelModule,
          // 国际化
          customTranslateModule,
        ],
        moddleExtensions: {
          activiti: activitiModdleDescriptor,
        },
      });
    },
    // 打开新建
    newLiucheng() {
      if (this.flag) {
        Message({
          message: "流程已存在",
          type: "warning",
        });
      } else {
        this.flowPath = true;
      }
    },
    // 新建流程图
    newflowPath(id, name) {
      this.flowPath = false;
      let xmlStr = xml(this.option.id, this.option.name);
      // let xmlStr = xml(this.option.id, this.option.name);
      this.bpmnModeler.importXML(xmlStr, (err) => {
        if (err) {
          Notification({
            title: "提示",
            message: "新建失败",
            type: "error",
            duration: 2000,
          });
        } else {
          Notification({
            title: "提示",
            message: "新建成功",
            type: "success",
            duration: 2000,
          });
          this.flag = true;
        }
      });
    },
    // 初始化位置大小
    center() {
      this.bpmnModeler.get("canvas").zoom("fit-viewport", "auto"); //画布自适应居中
      this.bpmnModeler.get("canvas").zoom(1.0); //放大至1倍
    },
    // 打开下载弹窗并获取xmml
    getXmlstr() {
      if (this.flag) {
        this.bpmnModeler
          .saveXML({ format: true })
          .then((res) => {
            this.xml = res.xml;
            this.xmlFlag = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Message({
          message: "未创建流程",
          type: "warning",
        });
      }
    },
    // 导出BPMN
    xmlDownload() {
      var xmlBlob = new Blob([this.xml], {
        type: "application/bpmn20-xml;charset=UTF-8,",
      });
      var downloadLink = document.createElement("a");
      downloadLink.download = `${this.xmlFlieName}.bpmn`;
      downloadLink.innerHTML = "Get BPMN SVG";
      downloadLink.href = window.URL.createObjectURL(xmlBlob);
      downloadLink.onclick = function (event) {
        document.body.removeChild(event.target);
      };
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.xmlFlag = false;
    },
    // 打开Svg弹窗并获取svg
    exportSvg() {
      if (this.flag) {
        this.bpmnModeler
          .saveSVG()
          .then((res) => {
            this.svg = res.svg;
            this.svgFlag = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Message({
          message: "流程未创建",
          type: "warning",
        });
      }
    },
    // 下载SVG
    svgDownload() {
      var svgBlob = new Blob([this.svg], {
        type: "image/svg+xml",
      });
      var downloadLink = document.createElement("a");
      downloadLink.download = `${this.svgFlieName}.svg`;
      downloadLink.innerHTML = "Get BPMN SVG";
      downloadLink.href = window.URL.createObjectURL(svgBlob);
      downloadLink.onclick = function (event) {
        document.body.removeChild(event.target);
      };
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.svgFlag = false;
    },
    // 打开流程图弹窗并获取svg
    getSvgstr() {
      if (this.flag) {
        this.bpmnModeler
          .saveSVG()
          .then((res) => {
            if (res.svg.indexOf("<svg") != -1) {
              let svg = res.svg.substr(res.svg.indexOf("<svg"));
              this.$refs.svg.innerHTML = svg;
              this.imgFlag = true;
              this.input = this.option.name;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Message({
          message: "未创建流程",
          type: "warning",
        });
      }
    },
    // 下载流程图
    download() {
      this.imgFlag = false;
      var svgDom = document.querySelector(".svg>svg");
      let svgToImg = new svgChange.svgToImg(svgDom);
      svgToImg.change(this.input, this.type);
    },
    // 导入bpmn文件
    beforeUpload(file) {
      let _this = this;
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        let xmlStr = this.result;
        _this.bpmnModeler.importXML(xmlStr, (err) => {
          if (err) {
            Notification({
              title: "提示",
              message: "导入失败",
              type: "error",
              duration: 2000,
            });
          } else {
            Notification({
              title: "提示",
              message: "导入成功",
              type: "success",
              duration: 2000,
            });
            _this.flag = true;
          }
        });
      };
    },
    saveFile() {
      const uuid = getUUID();
      // 保存预览图
      this.bpmnModeler
        .saveSVG()
        .then((res) => {
          this.svg = res.svg;
          let key;
          if (flowChatObj.flowChatPreview) {
            // 存在时直接截取
            key = flowChatObj.flowChatPreview
              .split("/")
              .slice(3)
              .join("/");
          } else {
            key = `${this.option.name}-${uuid}.svg`;
          }
          this.uploadFile(this.svg, key);
        })
        .catch((err) => {
          console.log(err);
        });
      // 保存流程图
      this.bpmnModeler
        .saveXML({ format: true })
        .then(async (res) => {
          this.xml = res.xml;
          let key;
          if (flowChatObj.flowChatUrl) {
            // 存在时直接截取
            key = flowChatObj.flowChatUrl
              .split("/")
              .slice(3)
              .join("/");
          } else {
            key = `${this.option.name}-${uuid}`;
          }
          this.uploadFile(this.xml, key);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async uploadFile(file, key) {
      const tokenObj = await getTokenByKey(key);
      if (tokenObj) {
        const config = {
          useCdnDomain: false,
          region: qiniu.region["z2"],
          disableStatisticsReport: true,
        };
        const putExtra = {
          params: {},
          mimeType: null,
        };
        const observable = qiniu.upload(
          file,
          key,
          tokenObj.token,
          putExtra,
          config
        );
        const subscription = observable.subscribe({
          next(res) { },
          error(err) {
            console.log(1, err);
          },
          async complete(res) {
            if (res.hash && res.key) {
              // 修改操作不保存数据库
              if (flowChatObj.flowChatPreview && flowChatObj.flowChatUrl) {
                if (res.key.endsWith(".svg")) {
                  flowChatObj.uploadSvg = true;
                } else {
                  flowChatObj.uploadXML = true;
                }

                if (flowChatObj.uploadSvg && flowChatObj.uploadXML) {
                  Notification({
                    title: "提示",
                    message: "保存成功",
                    type: "success",
                    duration: 2000,
                  });
                  flowChatObj.uploadSvg = false;
                  flowChatObj.uploadXML = false;
                }
                return;
              }
              const url = tokenObj.cdnUrl + res.key;
              if (res.key.endsWith(".svg")) {
                flowChatObj.flowChatPreview = url;
              } else {
                flowChatObj.flowChatUrl = url;
              }
              if (flowChatObj.flowChatUrl && flowChatObj.flowChatPreview) {
                // 保存作品数据
                const studentStudyId = await saveFlow(flowChatObj);
                if (studentStudyId) {
                  Notification({
                    title: "提示",
                    message: "保存成功",
                    type: "success",
                    duration: 2000,
                  });
                }
              }
              this.flag = true;
            } else {
              alert("保存失败, 请联系管理员");
            }
          },
        });
      }
    },
  },
  async mounted() {
    await this.createFlowChart();
    const studentStudyId = flowChatObj.studentStudyId;
    if (studentStudyId) {
      const flowChat = await getStudentStudy(studentStudyId);
      // 若存在流程图则进行加载,否则创建新的流程图
      if (flowChat.flowChatPreview && flowChat.flowChatUrl) {
        flowChatObj.flowChatUrl = flowChat.flowChatUrl;
        flowChatObj.flowChatPreview = flowChat.flowChatPreview;
        this.loadFlowChat();
      } else {
        this.newflowPath();
      }
    }
  },
};
</script>

<style lang="less" scoped>
.containers {
  display: flex;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 100%;

  .btnGroup {
    width: 100%;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
  }

  .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -50;
    transform: translate(-50%, -50%);
    display: none;
  }
}

.canvas {
  width: 100%;
  height: 100%;
}

.panel {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 300px;
  max-width: 300px;
  height: 100%;

  /deep/ .bpp-properties-panel {
    height: 100%;
  }

  /deep/ .bpp-textfield input {
    width: 100%;
    box-sizing: border-box;
  }

  /deep/ .bpp-properties-panel [contenteditable] {
    width: 100%;
    box-sizing: border-box;
  }
}

/deep/ .el-select {
  width: 100%;
}

/deep/ .el-dialog__body {
  padding: 30px 20px 10px;
}

/deep/ .el-dialog__footer {
  padding: 10px 20px;
  text-align: center;
}

.buttons {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.buttons li {
  display: inline-block;
  margin: 5px;
}

.buttons li a {
  color: #333;
  background: #fff;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ccc;
  text-decoration: none;
}
</style>
